import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
const cards = [
    {
        size: {
            height: 1,
            width: 2,
        },
        type: 'background',
    },
    {
        options: {
            ...ADFOX_CODES_DESKTOP.articlePartner,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'ad-article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
];
export default cards;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IQXal{position:relative}@media screen and (min-width:1236px){.IQXal{max-width:980px}}@media screen and (min-width:1552px){.IQXal{max-width:1296px}}.IQXal img{-o-object-fit:cover;object-fit:cover}.ZfmfY [id^=adfox]{display:flex;width:100%!important;align-items:center;justify-content:center;overflow-x:clip}.JkVE-{min-height:298px;padding:24px;background:#e2e2e2}@media screen and (min-width:768px){.JkVE-{min-height:314px;padding:32px 24px}}.Isi7p{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IQXal",
	"adfox": "ZfmfY",
	"banner": "JkVE-",
	"loader": "Isi7p"
};
module.exports = ___CSS_LOADER_EXPORT___;

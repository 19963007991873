import { defineComponent, onMounted, useContext, useCssModule } from '@nuxtjs/composition-api';
import { createHead } from '~/utils';
import { columns, presets } from '~/composables/grid/education';
import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
import { useModal } from '~/composables';
export default defineComponent({
    name: 'PageEducation',
    setup() {
        /**
         * Composables
         */
        const context = useContext();
        const css = useCssModule();
        const modal = useModal();
        const adfoxConfig = ADFOX_CODES_DESKTOP['300x600_1'];
        onMounted(() => {
            const { confirm = '', email = '' } = context.query.value || {};
            if (confirm === '1') {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title: 'Отлично! Вы подписались на рассылку Forbes Education',
                        text: `На указанный вами email ${email} будут приходить свежие новости и рассылки.`,
                        button: 'Хорошо',
                    },
                });
                history.replaceState(null, '', location.pathname);
            }
        });
        return {
            adfoxConfig,
            columns,
            css,
            presets,
        };
    },
    async asyncData(context) {
        const { app, env, error, route } = context;
        try {
            const title = 'Forbes Education';
            const head = {
                canonical: env.HOST + route.path,
                title,
                url: env.HOST + route.path,
            };
            context.$page = app.$page = {
                title,
            };
            return {
                $page: context.$page,
                head,
                title,
            };
        }
        catch (e) {
            console.error(e);
            error({ statusCode: 404 });
        }
    },
    head() {
        return createHead(this.head);
    },
});

import { computed, defineComponent, ref, useContext, useCssModule } from '@nuxtjs/composition-api';
import { ADFOX_CODES_DESKTOP, ADFOX_CODES_MOBILE, ADFOX_FETCH_STATES } from '~/components/Adfox';
import { useA11y } from '~/composables';
import { SHOULD_DISABLE } from '~/plugins/adfox';
export default defineComponent({
    name: 'PageHeaderAdfox',
    setup() {
        /**
         * Composables
         */
        const a11y = useA11y();
        const context = useContext();
        const css = useCssModule();
        const viewport = context.$viewport;
        /**
         * HTML elements & API
         */
        const bannerEl = ref();
        const mutationObserver = ref();
        /**
         * Should render banner
         */
        const shouldRender = ref(!SHOULD_DISABLE);
        /**
         * Adfox current
         */
        const currentError = ref({});
        const currentStates = ref({ ...ADFOX_FETCH_STATES });
        /**
         * Adfox config
         */
        const config = computed(() => {
            const config = viewport.isLessThan('tablet') ? ADFOX_CODES_MOBILE['300x250_1'] : ADFOX_CODES_DESKTOP.billboard;
            config.shouldSyncSession = true;
            return config;
        });
        /**
         * Adfox background config
         */
        const backgroundConfig = ref();
        /**
         * Adfox onComplete
         */
        function onComplete({ error = {}, state }) {
            window.dispatchEvent(new CustomEvent('completePageHeaderAdfox', {
                detail: { error, state },
            }));
            currentError.value = error;
            currentStates.value = state;
            if (!bannerEl.value) {
                return;
            }
            const adfoxEl = bannerEl.value.querySelector('[id^="adfox"]');
            if (!adfoxEl) {
                return;
            }
            let height = bannerEl.value.clientHeight;
            const imgs = adfoxEl.querySelectorAll('img');
            if (imgs.length === 1) {
                height = imgs[0].clientHeight;
            }
            bannerEl.value.style.removeProperty('min-height');
            bannerEl.value.style.setProperty('min-height', `${height}px`);
            if (viewport.isGreaterThan('mobileWide')) {
                backgroundConfig.value = ADFOX_CODES_DESKTOP.background;
                if (backgroundConfig.value) {
                    backgroundConfig.value.shouldSyncSession = true;
                }
            }
        }
        return {
            a11y,
            backgroundConfig,
            bannerEl,
            config,
            css,
            currentError,
            currentStates,
            onComplete,
            mutationObserver,
            shouldRender,
        };
    },
});

import { computed, defineComponent, useContext, useCssModule } from '@nuxtjs/composition-api';
import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
import { isFlutter } from '~/utils';
export default defineComponent({
    name: 'Page',
    props: {
        gap: {
            default: 'default',
            type: String,
            validator(value) {
                return ['default', 'thin'].includes(value);
            },
        },
        withSvgPattern: {
            default: false,
            type: Boolean,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const context = useContext();
        /**
         * Adfox configs
         */
        const configs = {
            default: ADFOX_CODES_DESKTOP['300x600_1'],
            // background: ADFOX_CODES_DESKTOP.background,
        };
        const shouldRenderAds = computed(() => !isFlutter(context.$device.userAgent));
        return {
            css,
            configs,
            shouldRenderAds,
        };
    },
});

import { presets } from '~/composables/grid/education-cards';
import { EDUCATION_SUBRUBRICS, EDUCATION_TAGS } from '~/constants';
import { ADFOX_CODES_MOBILE } from '~/components/Adfox';
const itemWidth = {
    mobile: '79.75vw',
    mobileMedium: '79.75vw',
    mobileWide: '319px',
};
const cards = [
    {
        options: {
            rubrics: EDUCATION_SUBRUBRICS,
            tags: EDUCATION_TAGS,
            withoutData: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'menu',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            itemRows: 2,
            slug: 'education-new',
            type: 'news',
            withoutLazy: true,
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            presets,
            slug: 'education-main',
            withoutLazy: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'grid',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            itemRows: 2,
            slug: 'education-news',
            type: 'news',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            withoutData: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'subscription-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/education_ratings',
            slug: 'education-ratings',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/education-research',
            slug: 'education-research',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/education_opinion',
            slug: 'education-opinion',
            type: 'opinion',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    // {
    //   options: {
    //     itemWidth,
    //     itemGap: '8px',
    //
    //     moreTo: '/education_special',
    //
    //     slug: 'education-special',
    //     type: 'article',
    //
    //     withItemsShadows: true,
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 1,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     moreTo: '/',
    //
    //     slug: 'education-streams',
    //     type: 'article',
    //
    //     withItemsShadows: true,
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 1,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     itemWidth,
    //     itemGap: '8px',
    //
    //     moreTo: '/education_video',
    //
    //     slug: 'education-video',
    //     type: 'article',
    //
    //     withItemsShadows: true,
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 1,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     itemWidth,
    //     itemGap: '8px',
    //
    //     moreTo: '/education_podcasts',
    //
    //     slug: 'education-podcasts',
    //     type: 'podcast',
    //
    //     withItemsShadows: true,
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 1,
    //   },
    //
    //   type: 'slider-education',
    // },
    {
        options: ADFOX_CODES_MOBILE['300x250_2'],
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-mobile',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_kids',
            slug: 'education-kids',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_higher-education',
            slug: 'education-higher-education',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_business-education',
            slug: 'education-business-education',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_additional-education',
            slug: 'education-additional-education',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: ADFOX_CODES_MOBILE['300x250_3'],
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-mobile',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_professions',
            slug: 'education-professions',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_study-abroad',
            slug: 'education-study-abroad',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_languages',
            slug: 'education-languages',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
    {
        options: {
            itemWidth,
            itemGap: '8px',
            moreTo: '/tegi/education_selfstudy',
            slug: 'education-selfstudy',
            type: 'article',
            withItemsShadows: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-education',
    },
];
export default cards;

export const CARDS = {
    ad: () => import('~/components/CardAdfox/CardAdfox.vue'),
    'ad-mobile': () => import('~/components/CardAdfoxMobile/CardAdfoxMobile.vue'),
    'ad-article': () => import('~/components/CardArticleAdfox/CardArticleAdfox.vue'),
    'ad-background-mobile': () => import('~/components/CardBackgroundMobileAdfox/CardBackgroundMobileAdfox.vue'),
    actual: () => import('~/components/CardActual/CardActual.vue'),
    article: () => import('~/components/CardArticle/CardArticle.vue'),
    'article-mobile': () => import('~/components/CardArticleMobile/CardArticleMobile.vue'),
    background: () => import('~/components/CardBackground/CardBackground.vue'),
    'background-mobile': () => import('~/components/CardBackgroundMobile/CardBackgroundMobile.vue'),
    franchise: () => import('~/components/CardFranchise/CardFranchise.vue'),
    grid: () => import('~/components/CardGrid/CardGrid.vue'),
    list: () => import('~/components/CardList/CardList.vue'),
    'list-mobile': () => import('~/components/CardListMobile/CardListMobile.vue'),
    menu: () => import('~/components/CardMenu/CardMenu.vue'),
    news: () => import('~/components/CardNews/CardNews.vue'),
    opinion: () => import('~/components/CardOpinion/CardOpinion.vue'),
    partner: () => import('~/components/CardPartner/CardPartner.vue'),
    'face-club': () => import('~/components/CardFaceClub/CardFaceClub.vue'),
    'event-club': () => import('~/components/CardEventClub/CardEventClub.vue'),
    slider: () => import('~/components/CardSlider/CardSlider.vue'),
    'slider-mobile': () => import('~/components/SliderMobile/SliderMobile.vue'),
    'slider-education': () => import('~/components/CardSliderEducation/CardSliderEducation.vue'),
    story: () => import('~/components/CardStory/CardStory.vue'),
    'story-mobile': () => import('~/components/CardStoryMobile/CardStoryMobile.vue'),
    'subscription-education': () => import('~/components/CardEducationSubscription/CardEducationSubscription.vue'),
    'tabs-mobile': () => import('~/components/CardTabsMobile/CardTabsMobile.vue'),
};
export const CARDS_MIN_HEIGHTS = {
    article: 292,
    franchise: 311,
};
/**
 * Типы карточек, в которые не требуется прокидывать данные из списка.
 * (Это поведение можно также задавать для отдельной карточки внутри пересета через options.withoutData)
 */
export const CARD_TYPES_WITHOUT_DATA = ['ad', 'ad-mobile'];
export const DEFAULT_COLUMNS = {
    desktop: 3,
    desktopMedium: 3,
    desktopWide: 4,
    mobile: 1,
    mobileMedium: 1,
    mobileWide: 1,
    tablet: 2,
};

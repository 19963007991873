// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T6L99{display:grid;grid-gap:8px;gap:8px;grid-auto-rows:minmax(0,auto)}@media screen and (min-width:768px){.T6L99{padding:24px 0;grid-gap:16px;gap:16px}}@media screen and (min-width:1236px){.T6L99{padding:24px}}.Sa7ru{display:flex;overflow:hidden;width:100%;height:100%}@media screen and (min-width:768px){.Sa7ru{overflow:visible}}.pyfjO{width:100%;height:100%;background:rgba(0,130,255,.5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "T6L99",
	"card": "Sa7ru",
	"cardEmpty": "pyfjO"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MetMc{grid-gap:8px;gap:8px}._5FctL,.MetMc{display:flex;align-items:center}._5FctL{width:32px;height:32px;flex:0 0 32px;justify-content:center;background-color:#c3c3c3;border-radius:100%;color:#fff;transition:background-color .2s}._5FctL:active{-webkit-tap-highlight-color:rgba(0,0,0,0)}._5FctL:active.Fzy18{background-color:#f70}._5FctL:active.asKsR{background-color:#52a8e5}._5FctL:active.k1elF{background-color:#101418}._5FctL:active.GHDc\\+{background-color:#07f}._5FctL:active.UQtDM{background-color:red}._5FctL:active.AeM4P{background-color:#2c3036}@media(any-hover:hover){._5FctL:hover.Fzy18{background-color:#f70}._5FctL:hover.asKsR{background-color:#52a8e5}._5FctL:hover.k1elF{background-color:#101418}._5FctL:hover.GHDc\\+{background-color:#07f}._5FctL:hover.UQtDM{background-color:red}._5FctL:hover.AeM4P{background-color:#2c3036}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MetMc",
	"link": "_5FctL",
	"ok": "Fzy18",
	"tg": "asKsR",
	"x": "k1elF",
	"vk": "GHDc+",
	"yt": "UQtDM",
	"zen": "AeM4P"
};
module.exports = ___CSS_LOADER_EXPORT___;

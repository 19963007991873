import { computed, defineComponent, onMounted, ref, set, useContext, useCssModule, watch, } from '@nuxtjs/composition-api';
import { CARDS, CARDS_MIN_HEIGHTS, CARD_TYPES_WITHOUT_DATA, DEFAULT_COLUMNS } from './';
import { useGrid } from '~/composables';
import { useDuck } from '~/services';
import { LIST_EMPTY_TITLE_KEYWORDS } from '~/constants';
export default defineComponent({
    name: 'Grid',
    props: {
        /**
         * Articles.
         */
        articles: {
            default: () => [],
            type: Array,
        },
        columns: {
            default: () => ({}),
            type: Object,
        },
        listsData: {
            default: () => ({}),
            type: Object,
        },
        listsVisibility: {
            default: () => ({}),
            type: Object,
        },
        /**
         * Presets.
         */
        presets: {
            default: () => [],
            type: Array,
        },
        firstGrid: {
            default: false,
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        /**
         * Composables.
         */
        const context = useContext();
        const css = useCssModule();
        const duck = useDuck();
        /**
         * Viewport.
         */
        const viewport = context.$viewport;
        /**
         * Cards
         */
        const cards = CARDS;
        /**
         * Cards ref.
         */
        const cardsRef = ref([]);
        /**
         * Lists.
         */
        const lists = ref({
            ...props.listsData,
        });
        /**
         * Lists observer.
         */
        const listsObserver = ref();
        /**
         * Current preset.
         */
        const preset = ref([]);
        /**
         * Current columns.
         */
        const currentColumns = computed(() => {
            return {
                ...DEFAULT_COLUMNS,
                ...props.columns,
            };
        });
        /**
         * Returns a preset from the viewport breakpoint.
         */
        const createPreset = useGrid(props.presets, currentColumns.value);
        /**
         * Returns card list name.
         */
        function getCardListName({ options, type }) {
            return options?.slug ? `${type}:${options.slug}` : null;
        }
        /**
         * Returns card grid styles.
         */
        function getCardStyle({ size, type }) {
            let minHeight = '';
            if (viewport.isGreaterThan('mobileWide')) {
                minHeight = `${CARDS_MIN_HEIGHTS[type] || CARDS_MIN_HEIGHTS.article * size.height}px`;
            }
            return {
                'grid-column': `span ${size.width}`,
                ...(size.height && {
                    'grid-row': `span ${size.height}`,
                    'min-height': minHeight,
                }),
            };
        }
        /**
         * Get card with list
         */
        function getCardWithList(listName) {
            const [type, slug] = listName.split(':');
            return preset.value.find((i) => i.type === type && i.options?.slug === slug);
        }
        /**
         * Returns grid styles.
         */
        function getGridStyle() {
            const columns = currentColumns.value[viewport.breakpoint] || 1;
            return {
                'grid-template-columns': `repeat(${columns}, 1fr)`,
            };
        }
        function isCardVisible(card) {
            switch (card.type) {
                case 'list':
                case 'list-mobile':
                case 'slider':
                case 'slider-mobile':
                case 'slider-education': {
                    const listName = getCardListName(card);
                    if (listName && listName in props.listsVisibility) {
                        return props.listsVisibility[listName];
                    }
                    return card.type in cards;
                }
                default: {
                    return card.type in cards;
                }
            }
        }
        /**
         * Load card with list
         */
        function loadCardWithList(card, listName) {
            const list = lists.value[listName];
            if (list) {
                updateCard(card, list);
            }
            else {
                const [, slug] = listName.split(':');
                duck.pub.getPubListById(slug).then(({ data: responseData }) => {
                    set(lists.value, listName, responseData);
                    updateCard(card, responseData);
                });
            }
        }
        /**
         * Update card
         */
        function updateCard(card, data) {
            const title = data.blockName || data.name;
            set(card, 'data', data.articles);
            set(card.options, 'title', !LIST_EMPTY_TITLE_KEYWORDS.includes(title) ? title : '');
            set(card.options, 'comment', data.comment || '');
        }
        /**
         * Update the preset when viewport breakpoint changes.
         */
        watch(() => viewport.breakpoint, (breakpoint) => {
            const cards = createPreset(breakpoint);
            emit('updatePreset', cards);
            let previousArticleIndex = 0;
            // Reset the preset array.
            preset.value.length = 0;
            for (const card of cards) {
                const slug = card?.options?.slug;
                const withoutData = card?.options?.withoutData;
                if (CARD_TYPES_WITHOUT_DATA.includes(card.type) || withoutData || slug) {
                    const listName = getCardListName(card);
                    if (listName) {
                        const list = lists.value[listName];
                        if (list) {
                            const title = list.blockName || list.name;
                            card.options.title = !LIST_EMPTY_TITLE_KEYWORDS.includes(title) ? title : '';
                            card.options.comment = list.comment || '';
                            preset.value.push({
                                ...card,
                                data: list.articles,
                            });
                            continue;
                        }
                        if (slug) {
                            lists.value[listName] = null;
                        }
                    }
                    preset.value.push(card);
                    continue;
                }
                if (card.type.startsWith('list') || card.type.startsWith('slider')) {
                    const data = [];
                    let times = card.type.includes('mobile') ? 4 : 3;
                    while (times--) {
                        const item = (props.articles || [])[previousArticleIndex];
                        if (!item) {
                            continue;
                        }
                        data.push(item);
                        previousArticleIndex++;
                    }
                    if (data.length) {
                        preset.value.push({ ...card, data });
                        continue;
                    }
                }
                const data = (props.articles || [])[previousArticleIndex];
                previousArticleIndex++;
                if (!data) {
                    return;
                }
                preset.value.push({ ...card, data });
            }
        }, {
            immediate: true,
        });
        onMounted(() => {
            listsObserver.value = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.intersectionRatio < 0.2) {
                        continue;
                    }
                    const target = entry.target;
                    const listName = target.dataset.list;
                    if (!listName) {
                        continue;
                    }
                    const card = getCardWithList(listName);
                    if (!card) {
                        continue;
                    }
                    loadCardWithList(card, listName);
                    listsObserver.value?.unobserve(entry.target);
                }
            }, {
                threshold: Array.from({ length: 11 }, (_, key) => key * 0.1),
            });
            watch(cardsRef, (refs) => {
                for (const ref of refs) {
                    const listName = ref.dataset.list || '';
                    const card = getCardWithList(listName);
                    const shouldDisableLazyLoad = card?.options?.withoutLazy || false;
                    if (listName && !shouldDisableLazyLoad) {
                        listsObserver.value?.observe(ref);
                    }
                    else if (card && listName && shouldDisableLazyLoad) {
                        loadCardWithList(card, listName);
                    }
                }
            }, { immediate: true });
        });
        return {
            cards,
            cardsRef,
            css,
            currentColumns,
            getCardListName,
            getCardStyle,
            getGridStyle,
            isCardVisible,
            lists,
            preset,
        };
    },
});

import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
const cards = [
    {
        options: {
            ...ADFOX_CODES_DESKTOP.articlePartner,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-background-mobile',
    },
    {
        options: {
            withReadMore: false,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
];
export default cards;

export const SOCIAL_LINKS = {
    education: [
        '//t.me/forbes_education',
        '//vk.com/forbes_education',
        '//x.com/EducationForbes',
        '//www.youtube.com/@ForbesEducation',
    ],
    'forbes-woman': ['//t.me/ForbesWomanRussia', '//vk.com/forbes_woman'],
    forbeslife: ['//t.me/ForbesLifeRussia', '//vk.com/forbes.life'],
    franchise: ['//t.me/forbesfranchises', '//vk.com/forbesfranchises'],
    club: ['//t.me/forbesclubrussia', '//vk.com/forbesclub_russia'],
    special: ['//www.youtube.com/@forbesspecialprojects'],
    sport: [
        '//t.me/forbessportrussia',
        '//dzen.ru/forbessport',
        '//vk.com/forbessport',
        '//x.com/forbessport',
        '//ok.ru/group/70000002700637',
    ],
    video: ['//www.youtube.com/@ForbesRus'],
    young: ['//t.me/forbes_young', '//vk.com/forbes_young_russia'],
};
export const HOSTS_MAP = {
    'dzen.ru': {
        icon: () => import('./icons/zen.svg?inline'),
        id: 'zen',
        name: 'Дзен',
        title: 'Подпишитесь на наши новости в Яндекс.Дзен',
    },
    'ok.ru': {
        icon: () => import('./icons/ok.svg?inline'),
        id: 'ok',
        name: 'Одноклассники',
        title: 'Подпишитесь на нас в Одноклассниках',
    },
    't.me': {
        icon: () => import('./icons/tg.svg?inline'),
        id: 'tg',
        name: 'Telegram',
        title: 'Подпишитесь на нас в Telegram',
    },
    'x.com': {
        icon: () => import('./icons/tw.svg?inline'),
        id: 'x',
        name: 'X',
        title: 'Подпишитесь на нас в X',
    },
    'vk.com': {
        icon: () => import('./icons/vk.svg?inline'),
        id: 'vk',
        name: 'VK',
        title: 'Подпишитесь на нас в VK',
    },
    'youtube.com': {
        icon: () => import('./icons/yt.svg?inline'),
        id: 'yt',
        name: 'Youtube',
        title: 'Подпишитесь на наш YouTube-канал',
    },
};

import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { HOSTS_MAP, SOCIAL_LINKS } from './';
export default defineComponent({
    name: 'SocialLinks',
    props: {
        type: {
            required: true,
            type: String,
            validator(value) {
                return [
                    'education',
                    'forbes-woman',
                    'forbeslife',
                    'franchise',
                    'special',
                    'sport',
                    'video',
                    'young',
                    'club',
                ].includes(value);
            },
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const items = computed(() => {
            return SOCIAL_LINKS[props.type].map((link) => {
                let icon;
                let id;
                let name;
                let title;
                for (const [host, { icon: i, id: _id, name: n, title: t }] of Object.entries(HOSTS_MAP)) {
                    if (link.includes(host)) {
                        icon = i;
                        id = _id;
                        name = n;
                        title = t;
                        break;
                    }
                }
                return {
                    icon,
                    id,
                    link,
                    name,
                    title,
                };
            });
        });
        return {
            css,
            items,
        };
    },
});

import { columns, presets } from '~/composables/grid/education-cards';
import { EDUCATION_SUBRUBRICS, EDUCATION_TAGS } from '~/constants';
const cards = [
    {
        options: {
            rubrics: EDUCATION_SUBRUBRICS,
            tags: EDUCATION_TAGS,
            withoutData: true,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'menu',
    },
    {
        options: {
            slug: 'education-new',
            type: 'news',
            withoutLazy: true,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            columns,
            presets,
            slug: 'education-main',
            withoutLazy: true,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'grid',
    },
    {
        options: {
            itemRows: 2,
            slug: 'education-news',
            type: 'news',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/education_ratings',
            slug: 'education-ratings',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/education-research',
            slug: 'education-research',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/education_opinion',
            slug: 'education-opinion',
            type: 'opinion',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    // {
    //   options: {
    //     moreTo: '/education_special',
    //
    //     slug: 'education-special',
    //     type: 'article',
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 3,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     moreTo: '/',
    //
    //     slug: 'education-streams',
    //     type: 'article',
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 3,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     moreTo: '/education_video',
    //
    //     slug: 'education-video',
    //     type: 'article',
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 3,
    //   },
    //
    //   type: 'slider-education',
    // },
    // {
    //   options: {
    //     itemGap: '16px',
    //     itemWidth: '458px',
    //
    //     moreTo: `/podcasts?serials=${EDUCATIONS_PODCASTS.join(',')}`,
    //
    //     slug: 'education-podcasts',
    //     type: 'podcast',
    //   },
    //
    //   size: {
    //     height: 0,
    //     width: 3,
    //   },
    //
    //   type: 'slider-education',
    // },
    {
        options: {
            moreTo: '/tegi/education_kids',
            slug: 'education-kids',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_higher-education',
            slug: 'education-higher-education',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_business-education',
            slug: 'education-business-education',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_additional-education',
            slug: 'education-additional-education',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_professions',
            slug: 'education-professions',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_study-abroad',
            slug: 'education-study-abroad',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_languages',
            slug: 'education-languages',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
    {
        options: {
            moreTo: '/tegi/education_selfstudy',
            slug: 'education-selfstudy',
            type: 'article',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider-education',
    },
];
export default cards;

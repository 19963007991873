// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZuwfI{display:block}._2wxbh{display:flex;flex-flow:wrap;align-items:center;justify-content:space-between;margin:16px 8px;grid-gap:16px;gap:16px}@media screen and (min-width:400px){._2wxbh{margin:24px 16px}}@media screen and (min-width:768px){._2wxbh{margin:0 0 48px}}.zAMuL{display:inline;margin:0;color:#2d2d2d;font-family:\"Merriweather\",serif;font-size:26px;font-weight:700;line-height:30px}@media screen and (min-width:768px){.zAMuL{font-size:36px;line-height:44px}}.FYWvi{margin:0;font-size:17px;line-height:22px}.ZuwfI .\\-72yg{padding:0}@media screen and (min-width:768px){.ZuwfI .\\-72yg{grid-gap:48px;gap:48px}}.FqzMS{padding:16px 8px;font-size:17px;line-height:28px}@media screen and (min-width:400px){.FqzMS{padding-right:16px;padding-left:16px}}@media screen and (min-width:768px){.FqzMS{padding:48px 0 16px}}.FqzMS>strong{display:block;margin:0 0 10px;font-family:\"Merriweather\",serif;font-size:22px;font-weight:700;line-height:32px}.FqzMS>p{margin:0 0 24px}.FqzMS>p,.FqzMS>p>a{color:#232323}.\\+FB-f{display:flex;flex-flow:column;grid-gap:12px;gap:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "ZuwfI",
	"header": "_2wxbh",
	"title": "zAMuL",
	"subtitle": "FYWvi",
	"grid": "-72yg",
	"contacts": "FqzMS",
	"sidebar": "+FB-f"
};
module.exports = ___CSS_LOADER_EXPORT___;

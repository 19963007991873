import one from './presets/one';
import three from './presets/three';
import two from './presets/two';
/**
 * Columns
 */
export const columns = {
    desktop: 2,
    desktopMedium: 2,
    desktopWide: 3,
    mobile: 1,
    mobileMedium: 1,
    mobileWide: 1,
    tablet: 2,
};
/**
 * Columns presets.
 */
export const presets = [one, two, three, three];
